<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <span class="d-block small font-weight-bold text-cap mb-2">Pilot Programme Recruitment</span>
          <h1 class="mb-3">HK Open Banking SDK</h1>
          <p class="lead">Join our Pilot Programme of Open Banking SDK &amp; API today!</p>
          <a id="ga-product-sdk-hero-cta-button" class="btn btn-primary transition-3d-hover mt-3" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm">Join Pilot Now for FREE</a>
        </div>
        <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/svg/illustrations/sdk-pilot-programme-hero.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">What are Open APIs?</h2>
            <p>Over recent years, Open APIs have been applied by companies in different industry sectors to expand their core businesses and develop ecosystems that enable relevant, interconnected and intelligent customer experiences. For example, travel agency websites have leveraged Open APIs to access data from hotels and airlines in order to provide real-time booking and ticketing services.</p>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/resource-library/banking-open-api/open-apis.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Four Phases Section ========== -->
    <div class="bg-img-hero-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <!-- Title -->
        <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-6 mb-md-8">
          <h2 class="mb-3">The Four-Phased Approach of the Open API Framework in Hong Kong</h2>
        </div>
        <!-- Steps -->
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-6 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-1.svg">
              </figure>
              <h4>I. Product and service information</h4>
              <p>“Read-only” information offered by banks, providing details of their products and services.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-6 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-2.svg">
              </figure>
              <h4>II. Subscriptions and new applications for products/services</h4>
              <p>Customer acquisition processes, such as online submissions/applications for credit cards, loans, or other bank products.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-6 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-3.svg">
              </figure>
              <h4>III. Account information</h4>
              <p>Retrieval and alteration (where applicable) of the account information of authenticated customers, e.g. account balances, transactions (balances, transaction history, etc.), for stand-alone or aggregated viewing.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-6 mb-md-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-4.svg">
              </figure>
              <h4>IV. Transactions</h4>
              <p>Banking transactions and payments or scheduled payments/transfers initiated by authenticated customers.</p>
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
          <p class="small">* Extracted from “The Next Phase of the Banking Open API Journey” by HKMA, 2021</p>
        </div>
      </div>
    </div>
    <!-- ========== End Four Phases Section ========== -->

    <!-- ========== beNovelty Open Banking API & SDK Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-75 w-lg-80 text-center mx-auto mb-6 mb-md-8">
        <h2 class="mb-3">beNovelty Open Banking API &amp; SDK</h2>
        <p class="mb-0">beNovelty has successfully helped different enterprises connecting with banks and API platforms. We are now open for Pilot Programme Recruitment. Find out how beNovelty Open Banking API &amp; SDK works and join us today!</p>
      </div>
      <div class="w-90 w-md-75 mx-auto">
        <img class="img-fluid" src="@/assets/svg/illustrations/benovelty-open-banking-sdk.svg">
      </div>
      <div class="mt-11">
        <div class="rounded overflow-hidden space-top-2 space-top-lg-0 pl-0 pl-lg-8 shadow" style="background-color:#EAF4FD">
          <div class="row justify-content-lg-between align-items-lg-center no-gutters">
            <div class="col-lg-7 text-center text-center text-lg-left">
              <h2 class="mb-3">Interested in Our Pilot Programme?</h2>
              <p class="mb-4 lead">Join Us to Build a Better API Economy!</p>
              <a id="ga-product-sdk-main-cta-button" class="btn btn-primary transition-3d-hover mt-3" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm">Join Pilot Now for FREE</a>
            </div>
            <div class="col-lg-5 space-top-1 space-bottom-2 space-bottom-md-0 ml-auto">
              <div class="w-50 w-lg-75 mx-auto">
                <img class="img-fluid" src="@/assets/svg/illustrations/kong-environments.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End beNovelty Open Banking API & SDK Section ========== -->

    <!-- ===== Other Products Section ===== -->
    <div class="bg-light">
      <div class="container space-2 space-lg-3">
        <div class="mb-6 text-center">
          <h2>More by beNovelty</h2>
        </div>
        <div class="row w-lg-85 mx-auto" data-aos="fade-up">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <!-- <a href="/products/api-developer-portal" class="card h-100 transition-3d-hover"> -->
            <router-link class="card h-100 transition-3d-hover" :to="{path:'/products/api-developer-portal'}">
              <div class="w-sm-65 p-4">
                <h3>API Developer Portal</h3>
                <p>Create your branded API Developer Portal with ease.</p>
                <span>Find Out More<i class="fas fa-angle-right fa-sm ml-2" /></span>
              </div>
              <div class="position-absolute bottom-0 right-0 w-sm-25 max-w-23rem">
                <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
              </div>
            </router-link>
          <!-- </a> -->
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <!-- <a href="/products/fiberconnect" class="card h-100 transition-3d-hover"> -->
            <router-link class="card h-100 transition-3d-hover" :to="{path:'/products/fiberconnect'}">
              <div class="w-sm-65 p-4">
                <h3>FiberConnect</h3>
                <p>One-stop digital offerings for SME / Startups to achieve more in the business world.</p>
                <span>Find Out More<i class="fas fa-angle-right fa-sm ml-2" /></span>
              </div>
              <div class="position-absolute bottom-0 right-0 w-sm-25 max-w-23rem">
                <img class="img-fluid" src="@/assets/svg/icons/icon-22.svg">
              </div>
            </router-link>
          <!-- </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->
  </main>

</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'HKOpenBankingSDK',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'HK Open Banking SDK | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/hk-open-banking-sdk' }
      ],
      meta: [
        { name: 'description', content: 'An unified API and SDK for Hong Kong Open Banking Connectivities. Join our Pilot Programme for more!' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'HK Open Banking SDK | Products | beNovelty' },
        { property: 'og:description', content: 'An unified API and SDK for Hong Kong Open Banking Connectivities. Join our Pilot Programme for more!' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/hk-open-banking-sdk' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

